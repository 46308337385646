<template>
  <section class="containerPageNotFound">
    <img class="containerPageNotFound__image" src="@/assets/pageNotFound.svg"/>
    <p class="containerPageNotFound__messageWrong">Oops, something went wrong</p>
    <span class="containerPageNotFound__label">Page not found</span>
  </section>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'pageNotFound',
  setup (props) {
    const store = useStore()
    onMounted(_ => {
      store.commit({
        type: 'SET_FOOTER_VISIBILITY',
        flag: false
      })
    })
  }
}
</script>
